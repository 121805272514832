import React, { useEffect, useState } from "react";
import Header from "../SubComponent/Header";
import Footer from "../SubComponent/Footer";
import img from "../Assets/Images/logoo.png";
import gif from "../Assets/Images/loader.gif";
import "../Assets/Style/article.css";
import config from "../Config";
import axios from "axios";
import { useNavigate } from "react-router";

const SupportSecondary = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [articledetails, setArticleDetails] = useState({});
  let endpoint = window.location.pathname;
  // console.log(endpoint);
  let articleId = endpoint.replace("/", "");
  let tempdetail = {};
  let articleid = "";
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchData = async () => {
      try {
        axios
          .get(`${config.baseUrl}/user/get-blog-categories`)
          .then(async (res) => {
            for (let i = 0; i < res.data.blog_count.length; i++) {
              const { id, count } = res.data.blog_count[i];
              // console.log(res.data.blog_count[i]);
              const index = res.data.data.findIndex((obj) => obj.id === id);
              if (index !== -1) {
                res.data.data[index].count = count;
              }
            }
            for (let i = 0; i < res.data.data.length; i++) {
              const element = res.data.data[i];
              console.log(element);
              if (element.slug == articleId) {
                tempdetail = element;
                articleid = element.slug;
              }
            }
            // console.log(tempdetail);
            setArticleDetails(tempdetail);
            // console.log("articleid", articleid);
            await axios
              .post(`${config.baseUrl}/user/get-blogs-by-category-slug`, {
                slug: articleid,
              })
              .then((res) => {
                // console.log(res.data.data);
                setData(res.data.data);
                setLoader(false);
                //  console.log("categ",res.data.data);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleClick = (id) => {
    const index = Data.findIndex((el) => el.id == id);
    const element = Data[index];
    navigate(`/${articledetails.slug}/${element.slug}`);
  };
  return (
    <>
      {loader === true ? (
        <div
          id="loader"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            position: "absolute",
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
            zIndex: "99999",
            top: "0",
            left: "0",
          }}
        >
          <img style={{ width: "10%" }} src={gif} />
        </div>
      ) : (
        <>
          <Header />
          <div className="articleamin">
            <div className="articlesubamin">
              <div className="arrsection">
                <div className="articlehead">
                  <h3>All Collection / {articledetails.title}</h3>
                </div>
                <div className="arrsubsection">
                  <div className="cardHead3">
                    <img
                      className="fontawesome"
                      src={`data:image/jpeg;base64,${articledetails.image}`}
                      alt=""
                    />
                  </div>
                  <div className="cardHead2">
                    <div className="cardheadings">
                      <h2>{articledetails.title}</h2>
                      <p style={{ fontSize: "13px" }}>
                        {articledetails.description}
                      </p>
                    </div>
                    <div className="cardfoot">
                      {/* <div className="footimg">
                        <img src={img} alt="logo" />
                      </div> */}
                      <div className="footPara">
                        <p style={{ fontSize: "13px" }}>
                          {articledetails.count} articles in this collection
                        </p>
                        <p style={{ fontSize: "13px" }}>Written by NullShip</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="allarticles">
                  {Data.map((val, index) => {
                    return (
                      <div
                        onClick={(e) => handleClick(val.id)}
                        key={val.id}
                        className="cardarr"
                      >
                        <div className="cardarrhaed">
                          <h4>{val.title}</h4>
                          {/* <p>{val.excerpt}</p> */}
                          <img
                            className="arrowimg"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAUklEQVR4nM3RsRFAQBBG4S+SqYBAQtd6IFGMCzSgCHMl+M3cePl7u7PL35gxpvKKkgY67LgwfQkUDGlgw5kGetw40tVfT+6ai5X65/hISyq24QFsMw9qjY95gAAAAABJRU5ErkJggg=="
                          />
                        </div>
                        {/* <div className="cardarrbdy">
                          <div className="bdyimg">
                            <img src={img} alt="" />
                          </div>
                          <div className="bdytxt">
                            <p>
                              Written by {val.first_name} {val.last_name}{" "}
                            </p>
                            <p> Updated over a week ago</p>
                          </div>
                        </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default SupportSecondary;

import React from "react";
import img from "../Assets/Images/logoo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "200px",
        textAlign: "center",
        alignItems: "center",
        paddingTop: "20px",
        backgroundColor: "#2b3041",
      }}
    >
      <a href="https://nullship.gg/">
        <img src={img} alt="" style={{ width: "80px" }} />
      </a>
      <a href="https://nullship.gg/" style={{ textDecoration: "none" }} to="/">
        <h3
          style={{
            cursor: "pointer",
            fontSize: "20px",
            fontWeight: "400",
            color: "white",
            fontFamily: "Montserrat",
            fontStyle: "normal",
          }}
        >
          Go back to NullShip
        </h3>
      </a>
    </div>
  );
};
export default Footer;

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Support from './Component/Support';
import SupportSecond from './Component/SupportSecond';
import Supportthird from './Component/Supporthird';
function App() {
  return (
      <Routes>
      <Route exact  path={'/'}  element={<Support/>} />
      <Route exact  path={'/:articleId'} element={<SupportSecond/>} />
      <Route exact path={'/:articleId/:blogId'} element={<Supportthird/>} />
      </Routes>
  );
}

export default App;

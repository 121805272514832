import React, { useEffect, useState } from "react";
import SingleCard from "./SingleCard";
import axios from "axios";
import "../Assets/Style/cardsection.css";
import gif from "../Assets/Images/loader.gif";
import { useNavigate } from "react-router";
import config from "../Config";
const CardSection = () => {
  const Navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  let newarr = [];
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    axios
      .get(`${config.baseUrl}/user/get-blog-categories`)
      .then((res) => {
        for (let i = 0; i < res.data.blog_count.length; i++) {
          const { id, count } = res.data.blog_count[i];
          //   console.log(res.data.blog_count[i]);

          const index = res.data.data.findIndex((obj) => obj.id === id);
          if (index !== -1) {
            res.data.data[index].count = count;
          }
        }
        for (let i = 0; i < res.data.data.length; i++) {
          const element = res.data.data[i];
          if (element.count > 0) {
            newarr.push(element);
          }
        }
        // console.log(newarr);
        setData(newarr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleClick = (e) => {
    // console.log(e);
    Navigate(`/${e}`);
  };
  return (
    <div className="cardSection">
      {loader === true ? (
        <div
          id="loader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            position: "absolute",
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
            zIndex: "99999",
            top: "0",
            left: "0",
          }}
        >
          <img style={{ width: "10%" }} src={gif} />
        </div>
      ) : (
        <>
          {Data.map((val, index) => {
            return (
              <SingleCard
                handleClick={(e) => handleClick(val.slug)}
                key={val.id}
                icon={val.image}
                cardHeading={val.title}
                cardpara={val.description}
                foot1para={val.count}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default CardSection;

import React from "react";
import Header from "../SubComponent/Header";
import CardSection from "../SubComponent/CardSection";
import Footer from "../SubComponent/Footer";
const Support = ()=>{
    return(
        <>
        <Header/>
        <CardSection/>
        <Footer/>
        </>
    )
}

export default Support;
import React, { useEffect, useState } from "react";
import Header from "../SubComponent/Header";
import Footer from "../SubComponent/Footer";
import axios from "axios";
import "../Assets/Style/blog.css";
import Parser from "html-react-parser";
import gif from "../Assets/Images/loader.gif";
import img from "../Assets/Images/logoo.png";
import config from "../Config";
const Supportthird = () => {
  const [articlehead, setArticleHead] = useState("");
  const [blogdes, setblogdes] = useState({});
  const [loader, setLoader] = useState(true);
  const [blogHtml, setBlogHtml] = useState("");
  let endpoint = window.location.pathname;
  let articleid = endpoint.replace("/", "");
  let blogid = articleid.split("/");
  let blogDes = {};
  let bloghead = "";
  console.log(articleid);
  console.log(blogid);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchData = () => {
      try {
        axios
          .get(`${config.baseUrl}/user/get-blog-categories`)
          .then(async (res) => {
            for (let i = 0; i < res.data.data.length; i++) {
              const element = res.data.data[i];
              if (element.slug == blogid[0]) {
                articleid = element.slug;
                bloghead = element.title;
              }
            }
            // console.log("bloghead", bloghead);
            setArticleHead(bloghead);
            await axios
              .post(`${config.baseUrl}/user/get-blogs-by-category-slug`, {
                slug: blogid[0],
              })
              .then(async (res) => {
                for (let index = 0; index < res.data.data.length; index++) {
                  const element = res.data.data[index];
                  if (element.slug == blogid[1]) {
                    blogDes = element;
                  }
                }
                console.log("blogDes", blogDes);
                setblogdes(blogDes);
                await axios
                  .post(`${config.baseUrl}/user/get-blogs-by-slug`, {
                    slug: blogid[1],
                  })
                  .then((res) => {
                    console.log("res", res);
                    setBlogHtml(res.data.data[0].description);
                    setLoader(false);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {loader === true ? (
        <div
          id="loader"
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            margin: "auto",
            position: "absolute",
            width: "100%",
            height: "100vh",
            backgroundColor: "white",
            zIndex: "99999",
            top: "0",
            left: "0",
          }}
        >
          <img style={{ width: "10%" }} src={gif} />
        </div>
      ) : (
        <>
          <Header />
          <div className="blogmain">
            <div className="blogcenter">
              <div className="blogsec">
                <div className="blogdes">
                  <h4>
                    All Collection / {articlehead} / {blogdes.title}
                  </h4>
                </div>
                <div className="arrsubsection">
                  <div className="cardHead2">
                    <div className="cardheadings">
                      <h2>{blogdes.title}</h2>
                      <p>{blogdes.excerpt}</p>
                    </div>
                    <div className="cardfoot">
                      {/* <div className="footimg">
                                                <img src={img} alt="logo" />
                                            </div> */}
                      <div className="footPara">
                        <p style={{ fontSize: "13px" }}>Written by NullShip</p>
                        <p style={{ fontSize: "13px" }}>
                          Update over a week ago
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-detail">{Parser(blogHtml)}</div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Supportthird;

import { Link } from "react-router-dom";
import React, { useState } from "react";
import "../Assets/Style/header.css";
const Header = () => {
  const [inputColor, setInputColor] = useState("#353539");
  const [srColor, setsrColor] = useState("white");
  const [count, setCount] = useState(0);
  const [val, setVal] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    if (val.length <= 0) {
      setCount(0);
      setVal("");
    } else if (val.length > 0) {
      setVal(val);
      setCount(1);
    } else {
      setCount(0);
    }
  };
  const handleClick = () => {
    setInputColor("white");
    setsrColor("black");
  };
  const handleDelete = () => {
    setVal("");
    setCount(0);
  };
  return (
    <div className="headersec2">
      <div>
        <div className="mo o__centered o__reversed header__meta_wrapper">
          <div className="mo__body header__site_name"></div>
          <div className="mo__aside">
            <div className="header__links">
              <a href="https://nullship.gg/" className="header__home__url">
                <svg
                  //   style={{ color: "black" }}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#000000"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M11.5 6.73v6.77H.5v-11h7.615M4.5 9.5l7-7M13.5 5.5v-5h-5"></path>
                  </g>
                </svg>
                <span style={{ color: "black", fontSize: "14px" }}>
                  Go to NullShip
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="sub-head">
          <div className="headinghead">
            <h2>Expert Guidance and Solutions from the NullShip Team</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;

import React from "react";
import img from "../Assets/Images/logoo.png";
import "../Assets/Style/cardsection.css";
const SingleCard = (props) => {
  return (
    <div onClick={props.handleClick} className="cardHead">
      <div className="cardHead1">
        <img
          className="fontawesome"
          src={`data:image/jpeg;base64,${props.icon}`}
          alt=""
        />
      </div>
      <div className="cardHead2">
        <div className="cardheadings">
          <h2>{props.cardHeading}</h2>
          <p>{props.cardpara}</p>
        </div>
        <div className="cardfoot">
          {/* <div className="footimg">
                        <img src={img} alt="logo" />
                    </div> */}
          <div className="footPara">
            <p style={{ fontSize: "13px" }}>
              {props.foot1para} articles in this collection
            </p>
            <p style={{ fontSize: "13px" }}>Written by NullShip</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleCard;
